import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiEndpoints } from '../_helpers/api-endpoints';
import { Configuration } from '../_models/configuration.model';

@Injectable({
  providedIn: 'root'
})

export class ConfigurationService {

  constructor(private http: HttpClient) { }

  get(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(ApiEndpoints.configuration.get());
  }

  put(updated: Partial<Configuration>): Observable<Configuration> {
    return this.http.put<Configuration>(ApiEndpoints.configuration.put(), updated).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

}
