import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { ApiEndpoints } from '../_helpers/api-endpoints';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(ApiEndpoints.user.login(), { email, password})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    register(name:string,email: string, password: string, confirmPassword: string) {
      return this.http.post<any>(ApiEndpoints.user.register(), {name , email, password, confirmPassword });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(null);
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 500);
    }

    sendPasswordResetLink(email: string): Observable<any> {
        return this.http.post(ApiEndpoints.user.sendPasswordResetLink(), { email });
    }

    resetPassword(token: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this.http.post(ApiEndpoints.user.resetPassword(), { token, newPassword, confirmPassword });
    }
}