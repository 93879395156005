import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTES } from '../sidebar-menu/sidebar-menu.component';
import { User } from 'src/app/_models/user.model';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { ConfigurationService } from 'src/app/_services/config.service';
import { Configuration } from 'src/app/_models/configuration.model';
import * as _ from "lodash";
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

@Component({
  selector: 'app-touch-navbar',
  templateUrl: './touch-navbar.component.html',
  styleUrls: ['./touch-navbar.component.scss']
})

export class TouchNavbarComponent implements OnInit {

  public focus;
  public listTitles: any[];
  public location: Location;
  user: User;
  currentTimeString;
  configuration: Configuration;
  showNavbar: boolean = true;
  private hubConnectionBuilder!: HubConnection;
  
  constructor(location: Location,  private element: ElementRef, private router: Router, private authenticationService: AuthenticationService,  private configurationService: ConfigurationService) {
    this.location = location;
  }


  getConfiguration() {
    this.configurationService.get().subscribe({
      next: (data) => {
        this.configuration = _.cloneDeep(data);
      },
      error: (error) => {},
    });
  }

  ngOnInit() {
    this.getConfiguration();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.listTitles = ROUTES.filter(listTitle => listTitle);

    this.router.events.subscribe(() => {
      this.showNavbar = this.router.url !== '/login';
      this.showNavbar = this.router.url !== '/register';
    });

    this.updateClock();
    setInterval(() => this.updateClock(), 1000);


    this.hubConnectionBuilder = new HubConnectionBuilder()
    .withUrl(environment.baseSignalRUrl + "/hubs/configurationhub")
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

    this.hubConnectionBuilder
      .start()

    this.hubConnectionBuilder.on("ConfigurationUpdate", (result: any) => {
      var jsonOriginal = JSON.parse(result);
      let jsonLowercaseKeys: any = Object.keys(jsonOriginal).reduce(function (
        acc,
        key
      ) {
        let newKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[newKey] = jsonOriginal[key];
        return acc;
      },
      {});

      this.configuration = _.cloneDeep(jsonLowercaseKeys);
    });

  }

  toggleFullScreen() {
    if (!document.fullscreenElement) {
        // Enter fullscreen mode
        let elem = document.body;
        let methodToBeInvoked = elem.requestFullscreen;
        
        // Apply overflow styling to keep body scrollable
        if (methodToBeInvoked) {
            methodToBeInvoked.call(elem);
            document.body.style.overflow = 'auto';  // Ensure body remains scrollable
        }
    } else {
        // Exit fullscreen mode
        let methodToBeInvoked = document.exitFullscreen;
        
        if (methodToBeInvoked) {
            methodToBeInvoked.call(document);
            document.body.style.overflow = '';  // Reset overflow to default when exiting fullscreen
        }
    }
}



  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }

    return localStorage.getItem('touchGroupTitle');

  }

  logout(){
    this.authenticationService.logout()
  }

  updateClock() {
    var currentTime = new Date();
    // Operating System Clock Hours for 12h clock
    var currentHoursAP = currentTime.getHours();
    // Operating System Clock Hours for 24h clock
    var currentHours = currentTime.getHours();
    // Operating System Clock Minutes
    var currentMinutes: any = currentTime.getMinutes();
    // Operating System Clock Seconds
    var currentSeconds: any = currentTime.getSeconds();
    // Adding 0 if Minutes & Seconds is More or Less than 10
    currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
    currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
    // Picking "AM" or "PM" 12h clock if time is more or less than 12
    var timeOfDay = (currentHours < 12) ? "AM" : "PM";
    // transform clock to 12h version if needed
    currentHoursAP = (currentHours > 12) ? currentHours - 12 : currentHours;
    // transform clock to 12h version after mid night
    currentHoursAP = (currentHoursAP == 0) ? 12 : currentHoursAP;
    // display first 24h clock and after line break 12h version
    this.currentTimeString =  currentHours + ":" + currentMinutes + ":" + currentSeconds;
  }

}
